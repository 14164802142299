import request from '@/utils/request'


// 查询新-规格口味列表
export function listSkuFlavor(query) {
  return request({
    url: '/cigarette/goods-sku-flavor/list',
    method: 'get',
    params: query
  })
}


export function pageAdminSkuFlavor(query) {
  return request({
    url: '/cigarette/goods-sku-flavor/pageAdmin',
    method: 'get',
    params: query
  })
}
// 查询新-规格口味分页
export function pageSkuFlavor(query) {
  return request({
    url: '/cigarette/goods-sku-flavor/page',
    method: 'get',
    params: query
  })
}

// 查询新-规格口味详细
export function getSkuFlavor(data) {
  return request({
    url: '/cigarette/goods-sku-flavor/detail',
    method: 'get',
    params: data
  })
}

// 新增新-规格口味
export function addSkuFlavor(data) {
  return request({
    url: '/cigarette/goods-sku-flavor/add',
    method: 'post',
    data: data
  })
}

// 修改新-规格口味
export function updateSkuFlavor(data) {
  return request({
    url: '/cigarette/goods-sku-flavor/edit',
    method: 'post',
    data: data
  })
}

export function editStatus(data) {
  return request({
    url: '/cigarette/goods-sku-flavor/editStatus',
    method: 'post',
    data: data
  })
}

// 删除新-规格口味
export function delSkuFlavor(data) {
  return request({
    url: '/cigarette/goods-sku-flavor/delete',
    method: 'post',
    data: data
  })
}
