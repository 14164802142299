<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="批次名称(不能重复)" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入批次名称(不能重复)" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="b端生产用户id关联 {zb_user.id}" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入b端生产用户id关联 {zb_user.id}" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="品牌id关联{zb_brand.id}" prop="brandId">
                  <a-input v-model="queryParam.brandId" placeholder="请输入品牌id关联{zb_brand.id}" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="规格id关联{zb_goods_sku.id}" prop="skuId">
                  <a-input v-model="queryParam.skuId" placeholder="请输入规格id关联{zb_goods_sku.id}" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="品牌产品id 关联{zb_brand_sku.id}" prop="brandProduct">
                  <a-input v-model="queryParam.brandProduct" placeholder="请输入品牌产品id 关联{zb_brand_sku.id}" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="产品数量" prop="num">
                  <a-input v-model="queryParam.num" placeholder="请输入产品数量" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="曲线多个id" prop="curvesId">
                  <a-input v-model="queryParam.curvesId" placeholder="请输入曲线多个id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="弹出值(批次值大于app设置的值)" prop="popValue">
                  <a-input v-model="queryParam.popValue" placeholder="请输入弹出值(批次值大于app设置的值)" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:batch:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:batch:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:batch:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:batch:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['cigarette:batch:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:batch:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['cigarette:batch:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['cigarette:batch:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageBatch,listBatch, delBatch } from '@/api/cigarette/batch'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Batch',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        userId: null,
        brandId: null,
        skuId: null,
        brandProduct: null,
        num: null,
        notes: null,
        status: null,
        type: null,
        curvesId: null,
        popValue: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '批次名称(不能重复)',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'b端生产用户id关联 {zb_user.id}',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '品牌id关联{zb_brand.id}',
          dataIndex: 'brandId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '规格id关联{zb_goods_sku.id}',
          dataIndex: 'skuId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '品牌产品id 关联{zb_brand_sku.id}',
          dataIndex: 'brandProduct',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '产品数量',
          dataIndex: 'num',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '批次说明',
          dataIndex: 'notes',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态:0-待生产,1-待确认,2-正在生产,3-完成',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '弹出类型：0-弹出模板 1-网址',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '曲线多个id',
          dataIndex: 'curvesId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '弹出值(批次值大于app设置的值)',
          dataIndex: 'popValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询新-产品批次列表 */
    getList () {
      this.loading = true
     pageBatch(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        userId: undefined,
        brandId: undefined,
        skuId: undefined,
        brandProduct: undefined,
        num: undefined,
        notes: undefined,
        status: undefined,
        type: undefined,
        curvesId: undefined,
        popValue: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBatch(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/goods-batch/export', {
            ...that.queryParam
          }, `新-产品批次_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
